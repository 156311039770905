import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "core-js/stable";
import "regenerator-runtime/runtime";




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);